import React, { Component } from 'react';
import './Blurb.css';
import PropTypes from 'prop-types';

export default class Blurb extends Component {
  // constructor(props) {
  //     super(props);
  // }

  render() {
    return (
      <div className="blurb">
        <h2>{this.props.children}</h2>
      </div>
    );
  }
}

Blurb.propTypes = {
  children: PropTypes.object
};
