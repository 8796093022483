import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth, Cache } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';

// pages
import { Navbar } from 'reactstrap';
import Home from './pages/Home.jsx';
import Login from './pages/Login.jsx';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
// import EnvIndicator from './components/EnvIndicator';
import Footer from './components/Footer';
import ScrollButton from './components/ScrollButton.js';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.childProps = {};
    this.state = {
      isAuthenticated: false,
      isAuthenticating: false,
      courses: []
    };
  }

  logOut = async (callback) => {
    Auth.signOut();
    Cache.clear();
    window.localStorage.clear();
    this.userHasAuthenticated(false);
    callback();
  };

  userHasAuthenticated = (authenticated) => {
    window.localStorage.setItem('isOffice365Authenticating', false);
    this.setState({ isAuthenticated: authenticated, isAuthenticating: false });
  };

  userStartedAuthenticating = () => {
    window.localStorage.setItem('isOffice365Authenticating', true);
    this.setState({ isAuthenticating: true });
  };

  coursesLoaded = (courses) => {
    this.setState({ courses });
  };

  render() {
    this.childProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAuthenticating: this.state.isAuthenticating,
      courses: this.state.courses,
      coursesLoaded: this.coursesLoaded,
      userHasAuthenticated: this.userHasAuthenticated,
      userStartedAuthenticating: this.userStartedAuthenticating,
      logOut: this.logOut,
      auth: Auth
    };
    return (
      <div className="App">
        <ToastContainer />
        <Router>
          <Routes>
            <Route path="/login" element={<Login props={this.childProps} />} />
            <Route path="/" element={<Home props={this.childProps} />} />
          </Routes>
        </Router>
        <Footer />
        <Navbar fixed="bottom">
          <ScrollButton />
        </Navbar>
      </div>
    );
  }
}
