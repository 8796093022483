import React, { Component } from 'react';
import './TextCopier.css';
import copyIcon from '../icons/copy.png';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

export default class TextCopier extends Component {
  constructor(props) {
    super(props);
    this.clipboardAlert = React.createRef();

    this.state = {
      text: this.props.text,
      message: this.props.message,
      alertId: '',
      alert: null,
      showAlert: false
    };
  }

  copyText = () => {
    var textArea = document.createElement('textarea');
    textArea.value = this.state.text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    toast(this.state.message, {
      hideProgressBar: true,
      className: 'success-toast',
      bodyClassName: 'toast-body'
    });
  };

  render() {
    return (
      <React.Fragment>
        <span title="copy url">
          <div id="copier" style={this.props.style}>
            <div id="copy-button" onClick={this.copyText} style={{ width: '1rem', height: '1rem' }}>
              <img src={copyIcon} alt="copy url" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </span>
      </React.Fragment>
    );
  }
}

TextCopier.propTypes = {
  text: PropTypes.string,
  message: PropTypes.string,
  element: PropTypes.object,
  style: PropTypes.object
};
