import React, { Component } from 'react';
import FileWindow from './FileWindow';
import PropTypes from 'prop-types';
import { Card, CardText, Button, Spinner } from 'reactstrap';
import EnvironmentConfiguration from '../config';
var config = EnvironmentConfiguration.getConfig();

export default class ProjectCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: this.props.project,
      s3: this.props.s3,
      viewing: this.props.viewing ? this.props.viewing : false,
      bucket: this.props.bucket,
      deploying: this.props.deploying
    };
  }

  selectProject = () => {
    this.setState({ viewing: true });
  };

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.bucket !== this.state.bucket || nextProps.deploying !== this.state.deploying) {
      this.setState({
        bucket: nextProps.bucket,
        deploying: nextProps.deploying
      });
    }
  }

  render() {
    var bucketText;
    if (this.state.bucket === config.s3.TEST) {
      bucketText = 'Test';
    } else {
      bucketText = 'Live';
    }
    return (
      <React.Fragment>
        <Card
          style={{
            backgroundColor: 'gainsboro',
            opacity: this.state.deploying ? '0.7' : '1'
          }}>
          <CardText
            style={{
              textAlign: 'center',
              padding: '3px',
              margin: '0',
              width: '3rem',
              color: 'white',
              borderRadius: '0.2rem',
              backgroundColor: bucketText === 'Test' ? '#ff3333' : '#29a329'
            }}>
            {bucketText}
          </CardText>
          <div className="cardInner">
            <span title={this.state.project}>
              <h5
                style={{
                  height: '2rem',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}>
                {this.props.project.split('/')[1]}
              </h5>
            </span>
            {this.props.children}
            {this.state.deploying ? (
              <Spinner style={{ margin: 'auto', marginTop: '3%' }} />
            ) : (
              <Button
                color="primary"
                block
                onClick={() => {
                  this.selectProject();
                }}>
                View Project
              </Button>
            )}
          </div>
        </Card>
        <FileWindow
          open={this.state.viewing}
          closeProject={() => {
            this.setState({ viewing: false });
          }}
          s3={this.state.s3}
          prefix={this.state.project}
          bucket={this.state.bucket}
        />
      </React.Fragment>
    );
  }
}

ProjectCard.propTypes = {
  project: PropTypes.func,
  viewing: PropTypes.object,
  s3: PropTypes.object,
  bucket: PropTypes.object,
  deploying: PropTypes.object,
  children: PropTypes.object
};
