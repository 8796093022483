import React, { Component, Fragment } from 'react';
import { NavItem } from 'reactstrap';
import PropTypes from 'prop-types';

class EnvIndicator extends Component {
  constructor(props) {
    super(props);

    let envState = {};
    if (window.location.href.indexOf('localhost') !== -1) {
      envState = {
        envDisplay: 'Local Debug Environment',
        envHeaderClass: 'envIndicatorHeaderInnerLocal',
        envFooterClass: 'envIndicatorFooterLocal'
      };
    } else if (window.location.href.indexOf('fileuploader.engsand') !== -1) {
      envState = {
        envDisplay: 'File Uploader Dev Environment',
        envHeaderClass: 'envIndicatorHeaderInnerSandBox',
        envFooterClass: 'envIndicatorFooterSandBox'
      };
    } else if (window.location.href.indexOf('fileuploader.nonprod') !== -1) {
      envState = {
        envDisplay: 'File Uploader NonProd Environment',
        envHeaderClass: 'envIndicatorHeaderInnerNonProd',
        envFooterClass: 'envIndicatorFooterNonProd'
      };
    }

    this.state = envState;
  }

  render() {
    /* Render either top or bottom indicators or if production a fragment (nothing) */
    let renderThis = <></>;
    if (this.state.envDisplay) {
      if (this.props.position === 'top') {
        renderThis = (
          <NavItem>
            <div className="envIndicatorHeaderOuter">
              <div className={this.state.envHeaderClass} href="#">
                {this.state.envDisplay}
                <div className="envIndicatorHeaderSmall">
                  Do not enter production data on this site.
                </div>
              </div>
            </div>
          </NavItem>
        );
      } else if (this.props.position === 'bottom') {
        renderThis = (
          <div>
            <div className={this.state.envFooterClass}>
              {this.state.envDisplay} - Do not enter production data on this site.
            </div>
          </div>
        );
      }
    }
    return <span style={{ listStyle: 'none' }}>{renderThis}</span>;
  }
}

EnvIndicator.propTypes = {
  position: PropTypes.string
};

export default EnvIndicator;
