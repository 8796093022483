import styled from 'styled-components';

export const Button = styled.div`
  position: absolute;
  width: 100%;
  left: 43%;
  bottom: 5.5rem;
  font-size: 4.8rem;
  z-index: 1;
  cursor: pointer;
  color: #000f9f;
`;
