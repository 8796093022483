import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Amplify from 'aws-amplify';
import App from './App';
import environmentConfiguration from './config.js';

const config = environmentConfiguration.getConfig();

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: config.cognito.REDIRECT,
      redirectSignOut: config.cognito.REDIRECT,
      responseType: 'code'
    }
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
});

ReactDOM.render(<App />, document.getElementById('root'));
