import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import './SearchBar.css';

const SearchBar = (props) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    console.log('Mounting...');
  }, [searchValue]);

  const onSearchTextChanged = (text) => {
    setSearchValue(text);
    props.searchFunc(text);
  };

  return (
    <InputGroup className="searchBar">
      <InputGroup.Text>
        <FaSearch />
      </InputGroup.Text>
      <Form.Control
        type="text"
        placeholder="Search"
        onChange={(event) => onSearchTextChanged(event)}
      />
    </InputGroup>
  );
};
export default SearchBar;
