import React, { Component } from 'react';
import './LandingMessage.css';
import PropTypes from 'prop-types';

export default class LandingMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.active !== this.state.active) {
      this.setState({ active: nextProps.active });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.active === true ? (
          <div className="floatingWindow">
            <div style={{ position: 'relative' }}>{this.props.children}</div>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

LandingMessage.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.object
};
