import React, { Component } from 'react';
import TextCopier from './TextCopier';
import { Row, Col } from 'reactstrap';
import './FileWindow.css';
import PropTypes from 'prop-types';

// import EnvironmentConfiguration from '../config.js';

// var config = EnvironmentConfiguration.getConfig();

export default class FileWindowDirectory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      directory: this.props.directory,
      offset: this.props.offset,
      evenRowColor: this.props.evenRowColor,
      oddRowColor: this.props.oddRowColor,
      root: this.props.root,
      domain: this.props.domain,
      prefix: ''
    };
  }

  componentDidMount() {
    var key = Object.keys(this.state.directory)[0];
    this.setState({ prefix: key.split('/')[key.split('/').length - 2] });
  }

  renderDirectory = () => {
    var elementList = [];

    if (
      Object.keys(this.state.directory).length === 0 &&
      this.state.directory.constructor === Object
    ) {
      return;
    }

    for (var i = 0; i < Object.keys(this.state.directory).length; i++) {
      var key = Object.keys(this.state.directory)[i];
      var contents = this.state.directory[key];
      var count = 1;
      for (var n = 0; n < contents.length; n++) {
        var rowColor = n % 2 === 0 ? this.state.evenRowColor : this.state.oddRowColor;
        var content = contents[n];
        if (typeof content === 'string') {
          var fileName = content.split('/')[content.split('/').length - 1];
          elementList.push(
            <Row
              key={content}
              style={{
                justifyContent: 'left',
                backgroundColor: rowColor,
                transition: 'background-color 0.5s, color 0.5s'
              }}
            >
              <Col xs={{ size: 'auto' }}>
                <h4 style={{ marginLeft: this.state.offset + count + 'rem' }}>{fileName}</h4>
              </Col>
              <Col>
                <TextCopier
                  style={{ marginLeft: '50%' }}
                  message={'Successfully copied url to the clipboard!'}
                  text={this.state.domain + '/' + content}
                />
              </Col>
            </Row>
          );
        } else if (typeof content === 'object') {
          elementList.push(
            <FileWindowDirectory
              key={Object.keys(content)[0]}
              domain={this.state.domain}
              directory={content}
              offset={this.state.offset + count}
              evenRowColor={this.state.evenRowColor}
              oddRowColor={this.state.oddRowColor}
            />
          );
          count++;
        }
      }
    }

    return elementList;
  };

  render() {
    //  var fullPrefix = Object.keys(this.state.directory)[0];
    return (
      <div
        id="fileEntry"
        style={{
          textAlign: 'left',
          marginBottom: '4%',
          borderTop: '2px solid black',
          padding: '0'
        }}
      >
        <Row style={{ justifyContent: 'left' }}>
          <Col xs={{ size: 'auto' }}>
            {<h2 style={{ marginLeft: this.state.offset + 'rem' }}>{this.state.prefix}</h2>}
          </Col>
        </Row>
        {this.renderDirectory()}
      </div>
    );
  }
}

FileWindowDirectory.propTypes = {
  config: PropTypes.object,
  directory: PropTypes.object,
  offset: PropTypes.object,
  evenRowColor: PropTypes.string,
  oddRowColor: PropTypes.string,
  root: PropTypes.object,
  domain: PropTypes.string
};
