import React from 'react';
import { Navbar } from 'reactstrap';
import EnvIndicator from './EnvIndicator';

const style = {
  textAlign: 'center',
  justifyContent: 'center',
  listStyle: 'none',
  width: '100%',
  fontSize: '70%'
};

function Footer() {
  return (
    <footer>
      <Navbar
        fixed="bottom"
        color="light"
        light
        expand="xs"
        className="border-top border-gray bg-white"
      >
        <div style={style}>
          <EnvIndicator position="bottom" />
          This is an <a href="https://www.umassmed.edu/about/official/">official page</a> of the
          UMass Chan Medical School
          <br />
          55 Lake Avenue North, Worcester, MA 01545
          <br />
          <a href="https://www.umassmed.edu/about/privacy-statement/">Privacy Statement</a>
          <br />
        </div>
      </Navbar>
    </footer>
  );
}

export default Footer;
