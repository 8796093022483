import React, { Component } from 'react';
import EnvironmentConfiguration from '../config';
import Title from './PageTitle';
import { Cache } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

var config = EnvironmentConfiguration.getConfig();
var AWS = require('aws-sdk');
var parent_ID = null;
var codepipeline;
var sts;
var codecommit;
export default class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revisionID: 'None',
      inProgressStage: 'None',
      lastSucceededStage: 'None',
      Token: '',
      approved_stage: false,
      fileNeedTobeLive: this.props.fileNeedTobeLive,
      isAuthenticated: this.props.isAuthenticated,
      stageChanged: this.props.stageChanged
    };
  }

  toast = (message) => {
    toast(message, {
      hideProgressBar: true,
      className: 'success-toast',
      bodyClassName: 'toast-body'
    });
  };

  componentDidMount() {
    this.props.auth.currentCredentials().then((credentials) => {
      sts = new AWS.STS({ credentials: credentials });
      codecommit = new AWS.CodeCommit({
        region: config.codeCommit.REGION,
        credentials: credentials,
        sessionToken: credentials.sessionToken,
        uploadedCourses: [],
        maxRetries: 3
      });
      sts.assumeRole(
        {
          RoleArn: 'arn:aws:iam::719721023010:role/cipc-webapp-codepipeline-access-role',
          RoleSessionName: 'webapp-access'
        },
        (err, data) => {
          if (err) {
            return;
          }
          var params = {
            accessKeyId: data.Credentials.AccessKeyId,
            secretAccessKey: data.Credentials.SecretAccessKey,
            sessionToken: data.Credentials.SessionToken,
            region: config.codePipeline.REGION
          };
          codepipeline = new AWS.CodePipeline(params);
          window.setInterval(this.getpipeline, 2000);
        }
      );
    });
  }

  componentWillUnmount() {
    window.clearInterval();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.inProgressStage !== this.state.inProgressStage) {
      var message =
        nextState.inProgressStage === 'None'
          ? 'No stages currently running.'
          : 'Advancing to the ' + nextState.inProgressStage + ' stage.';
      this.props.stageChanged(nextState.inProgressStage);
      this.toast(message);
    }
  }

  getpipeline = () => {
    var flag = Cache.getItem('approved_stage');
    var revision;
    if (flag) {
      this.setState({ approved_stage: flag });
    }

    codecommit.getBranch(
      {
        branchName: 'master',
        repositoryName: config.codeCommit.REPOSITORY_NAME
      },
      (err, data) => {
        if (data) {
          revision = data.branch.commitId;
        }

        var params = {
          name: config.codePipeline.PIPELINE
        };
        codepipeline.getPipelineState(
          params,
          async function (err, data) {
            //gets the codepipeline Json data
            if (data) {
              for (var i = data.stageStates.length - 1; i >= 0; i--) {
                var state = data.stageStates[i];
                var action = state.actionStates[0];

                if (!action.latestExecution) {
                  continue;
                }
                if (action.latestExecution.status === 'InProgress') {
                  var previousStage;
                  if (i === 0) {
                    previousStage = 'None';
                  } else {
                    previousStage = data.stageStates[i - 1].stageName;
                  }

                  this.setState(
                    {
                      inProgressStage: state.stageName,
                      lastSucceededStage: previousStage,
                      token: action.latestExecution.token,
                      revisionID: revision
                    },
                    () => {
                      if (this.state.revisionID !== 'None') {
                        this.getFiles();
                      }
                    }
                  );
                  return;
                }
              }
            }
            this.setState({ inProgressStage: 'None', previousStage: 'None' });
            if (this.state.revisionID !== 'None') {
              this.getFiles();
            }
          }.bind(this)
        );
      }
    );
  };

  getFiles() {
    if (this.state.inProgressStage === 'None') {
      this.setState({ fileNeedTobeLive: [] });
      this.props.getFileNeedTobeLive([]);
      return;
    }
    var params1 = {
      repositoryName: config.codeCommit.REPOSITORY_NAME,
      commitId: this.state.revisionID
    };
    codecommit.getCommit(params1, function (err, data) {
      if (data) {
        parent_ID = data['commit']['parents'][0];
      }
    });
    if (parent_ID) {
      var params = {
        afterCommitSpecifier: this.state.revisionID,
        repositoryName: config.codeCommit.REPOSITORY_NAME,
        beforeCommitSpecifier: parent_ID
      };
      var array = [];
      codecommit.getDifferences(
        params,
        function (err, data) {
          if (data) {
            data.differences.forEach((diff) => {
              var path = diff.afterBlob ? diff.afterBlob.path : diff.beforeBlob.path;
              var project = [path.split('/')[0], path.split('/')[1]].join('/') + '/';
              if (!array.includes(project)) {
                array.push(project);
              }
            });
            this.setState({ fileNeedTobeLive: array });
            this.props.getFileNeedTobeLive(array);
          } else {
            /* empty */
          }
        }.bind(this)
      );
    }
  }

  ApproveStage = () => {
    var params = {
      actionName: 'Approval',
      pipelineName: config.codePipeline.PIPELINE,
      result: {
        status: 'Approved',
        summary: 'Approved by User'
      },
      stageName: 'Approval',
      token: this.state.token
    };
    codepipeline.putApprovalResult(
      params,
      function (err, data) {
        if (data) {
          this.setState({ approved_stage: true });
        }
      }.bind(this)
    );
  };

  render() {
    if (this.state.inProgressStage === 'None') {
      this.props.setUploadState(true);
    } else {
      this.props.setUploadState(false);
    }

    return (
      <React.Fragment>
        <Container style={{ textAlign: 'left' }}>
          <Title>
            <h3>Status of Recent Changes</h3>
          </Title>
          <div style={{ padding: '0 20% 0 20%' }}>
            <Row>
              <Col>
                Previous Stage :{' '}
                <span style={{ color: 'green' }}>{this.state.lastSucceededStage}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                Current Stage : <span className="highlight">{this.state.inProgressStage}</span>
              </Col>
            </Row>
            <Row>
              <Col>Courses and Projects in Deployment :</Col>
            </Row>
            <ul>
              {this.state.fileNeedTobeLive.length > 0 ? (
                this.state.fileNeedTobeLive.map(function (name) {
                  return (
                    <li key="id">
                      <span className="highlight">{name}</span>
                    </li>
                  );
                })
              ) : (
                <span className="highlight">
                  <li>None</li>
                </span>
              )}
            </ul>
            {this.state.inProgressStage === 'None' ? (
              <Row>
                <Col>
                  {' '}
                  <p href="" style={{ color: 'blue' }}>
                    No Stages are running
                  </p>
                  <br />
                </Col>
              </Row>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {this.state.approved_stage === false &&
            this.state.inProgressStage !== 'Approval' &&
            this.state.inProgressStage !== 'LiveDeploy' ? (
              <Row>
                <Col>
                  {' '}
                  <p style={{ color: 'red' }}>
                    Approve button will appear when changes are ready to go live.
                  </p>
                </Col>
              </Row>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {this.state.approved_stage === true && this.state.inProgressStage !== 'None' ? (
              <Row>
                <Col>
                  {' '}
                  <p style={{ color: 'green' }}>Making Changes Live!</p>
                </Col>
              </Row>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {this.state.inProgressStage === 'Approval' ? (
              <Button id="approve" color="primary" onClick={() => this.ApproveStage()}>
                Approve Changes
              </Button>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {this.state.inProgressStage === 'TestDeploy' ? (
              <Button color="secondary" disabled>
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{' '}
                Approve Changes
              </Button>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

Status.propTypes = {
  fileNeedTobeLive: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  stageChanged: PropTypes.object,
  auth: PropTypes.object,
  getFileNeedTobeLive: PropTypes.array,
  setUploadState: PropTypes.bool
};
