import React, { Component } from 'react';
import Title from '../components/PageTitle.jsx';
import MainNavbar from '../components/Navbar';
import Amplify from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// reactstrap components
import { Container, Button, Form, FormGroup } from 'reactstrap';
import config from '../config.js';

var Auth = null;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      email: '',
      password: ''
    };
  }

  componentDidMount() {
    Auth = this.props.props.auth;
  }

  addClass = (element, newClass) => {
    var classes = element.className.split(' ');
    if (!classes.includes(newClass)) {
      element.className += ' ' + newClass;
    }
  };

  validateForm = () => {
    var emailElm = document.getElementById('email');
    var passElm = document.getElementById('password');

    if (this.state.email === '') {
      this.addClass(emailElm, 'is-invalid');
      return false;
    }
    if (this.state.password === '') {
      this.addClass(passElm, 'is-invalid');
      return false;
    }

    return true;
  };

  wrongPassword = () => {
    var emailElm = document.getElementById('email');
    var passElm = document.getElementById('password');

    this.addClass(emailElm, 'is-invalid');
    this.addClass(passElm, 'is-invalid');
  };

  redirect = (location) => {
    this.props.navigate(location, {
      state: {
        isAuthenticated: this.state.isAuthenticated,
        courses: this.props.props.courses
      }
    });
  };

  submit = async () => {
    Amplify.Logger.LOG_LEVEL = 'DEBUG';
    this.setState({ submitting: true });

    if (this.validateForm() === true) {
      try {
        console.log(this.props);
        await Auth.signIn(this.state.email, this.state.password);
        this.props.props.userHasAuthenticated(true);
        this.setState({ isAuthenticated: true });
        await Auth.currentCredentials();
        this.redirect('/');
      } catch (e) {
        this.wrongPassword();
      }
    }

    this.setState({ submitting: false });
  };

  federatedSubmit = async () => {
    this.props.props.userStartedAuthenticating();
    this.props.props.auth.federatedSignIn({
      provider: config.getConfig().cognito.IDENTITY_PROVIDER
    });
  };

  render() {
    console.log(this.state);
    var childProps = {
      ...this.props,
      isAuthenticated: this.state.isAuthenticated
    };
    return (
      <React.Fragment>
        <MainNavbar {...childProps} brandname="CIPC" />
        <Container id="content">
          <Title>
            <h1>
              Log in to <span className="highlight">CIPC File Uploader</span>
            </h1>
          </Title>
          <Form>
            <FormGroup>
              {/* <Label for="email">Email</Label>
                            <Input type="email" name="email" id="email" placeholder="UMMS Email" onChange={(newEmail) => this.setState({email: newEmail.target.value})} />
                            <Label for="password">Password</Label>
                            <Input type="password" name="password" id="password" placeholder="Password" onChange={(newPass) => this.setState({password: newPass.target.value})}/>
                            <Button id="submit" color="primary" onClick={() => this.submit(childProps)}>Submit</Button> */}
              <Button id="submit" color="primary" onClick={() => this.federatedSubmit()}>
                Log in with Office 365
              </Button>
            </FormGroup>
          </Form>
        </Container>
      </React.Fragment>
    );
  }
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component location={location} navigate={navigate} {...props} />;
  }
  return ComponentWithRouterProp;
}

Login.propTypes = {
  props: PropTypes.object,
  navigate: PropTypes.object
};

export default withRouter(Login);
