import React from 'react';
import LandingMessage from '../components/LandingMessage.jsx';
import StatusMessage from '../components/StatusMessage.jsx';
import Title from '../components/PageTitle.jsx';
import Blurb from '../components/Blurb.jsx';
import Library from '../components/Library.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import Status from '../components/Status.jsx';
import Upload from '../components/Upload.jsx';
import Rerenderer from '../components/Rerenderer.jsx';
import { Container, Button } from 'reactstrap';
import MainNavbar from '../components/Navbar';
import { Hub, Cache } from 'aws-amplify';
import PropTypes from 'prop-types';

import EnvironmentConfiguration from '../config';

var config = EnvironmentConfiguration.getConfig();

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      landingMessage: true,
      isAuthenticated: this.props.props.isAuthenticated,
      upload: false,
      status: false,
      canUpload: true,
      bucket: config.s3.TEST,
      dropdownOpen: false,
      fileNeedTobeLive: [],
      pipelineStage: 'None'
    };
  }

  stageChanged = (newStage) => {
    this.setState({ pipelineStage: newStage });
  };

  setUploadState = (response) => {
    if (response !== this.state.canUpload) {
      this.setState({ canUpload: response });
    }
  };

  getFileNeedTobeLive = (resp) => {
    this.setState({ fileNeedTobeLive: resp });
  };

  getCognitoUser = async () => {
    await this.props.props.auth
      .currentAuthenticatedUser({
        bypassCache: false
      })
      .then((user) => {
        var name =
          user.signInUserSession.idToken.payload.given_name &&
          user.signInUserSession.idToken.payload.family_name
            ? `${user.signInUserSession.idToken.payload.given_name} ${user.signInUserSession.idToken.payload.family_name}`
            : null;
        Cache.setItem('Name', name);
        this.props.props.userHasAuthenticated(true);
      })
      .catch(() => {
        this.props.props.userHasAuthenticated(false);
        this.redirect('/login');
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.props.isAuthenticated !== this.state.isAuthenticated) {
      console.log(this.state);
      if (nextProps.props.isAuthenticated === false) {
        this.getCognitoUser();
      }
    }
  }

  componentDidMount() {
    if (
      window.localStorage.getItem('isOffice365Authenticating') === 'false' ||
      !window.localStorage.getItem('isOffice365Authenticating')
    ) {
      this.getCognitoUser();
    } else {
      window.setTimeout(() => {
        if (
          !this.state.isAuthenticated &&
          window.localStorage.getItem('isOffice365Authenticating') === 'true'
        ) {
          this.props.props.userHasAuthenticated(false);
          window.localStorage.setItem('isOffice365Authenticating', false);
          this.redirect('/login');
        }
      }, 3000);
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'cognitoHostedUI':
            break;
          case 'signIn':
            this.getCognitoUser();
            break;
          case 'signIn_failure':
            break;
          default:
            this.redirect('/login');
            break;
        }
      });
    }
  }

  componentWillUnmount() {
    Hub.remove('auth');
  }

  redirect = (location) => {
    this.props.navigate(location, {
      state: {
        isAuthenticated: this.props.props.isAuthenticated,
        courses: this.props.props.courses
      }
    });
  };

  toggleWindow = () => {
    this.setState({ landingMessage: !this.state.landingMessage });
  };

  render() {
    if (!this.props.props.isAuthenticated) {
      return <React.Fragment />;
    }
    var childProps = { ...this.props.props };
    return (
      <React.Fragment>
        <MainNavbar {...childProps} brandname="CIPC File Uploader">
          <span title="Upload a project">
            <Button
              aria-label="Upload button"
              color="primary"
              onClick={() => this.setState({ upload: true, status: false })}
              disabled={!this.state.canUpload}>
              Upload
            </Button>
          </span>
          <span title="Show status of committed projects">
            <Button
              aria-label="Project status button"
              style={{ width: '80px', marginLeft: '40px' }}
              color="primary"
              onClick={() => {
                this.setState({ status: true, upload: false });
              }}>
              {' '}
              Status
            </Button>
          </span>
        </MainNavbar>

        <StatusMessage active={this.state.status}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}>
            <Button
              onClick={() => this.setState({ status: false })}
              style={{
                position: 'relative',
                width: '2rem',
                height: '2rem',
                margin: '2%'
              }}
              close
            />
          </div>
          <Status
            isAuthenticated={this.props.props.isAuthenticated}
            auth={this.props.props.auth}
            setUploadState={this.setUploadState}
            canUpload={this.state.canUpload}
            fileNeedTobeLive={this.state.fileNeedTobeLive}
            getFileNeedTobeLive={this.getFileNeedTobeLive}
            stageChanged={this.stageChanged}
          />
        </StatusMessage>

        <Rerenderer seconds={2}>
          {this.state.upload ? (
            <LandingMessage active={true}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}>
                <Button
                  onClick={() => this.setState({ upload: false })}
                  style={{
                    position: 'relative',
                    width: '2rem',
                    height: '2rem',
                    margin: '2%'
                  }}
                  close
                />
              </div>
              <Upload
                {...this.props.props}
                setUploadState={this.setUploadState}
                canUpload={this.state.canUpload}
              />
            </LandingMessage>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <Container id="content">
            <Title style={{ width: '100%' }}>
              <h1>
                The <span className="highlight">CIPC File Uploader</span>
              </h1>
            </Title>
            <Blurb>A repository of CIPC files.</Blurb>

            {this.props.props.isAuthenticated ? (
              <Library
                {...childProps}
                style={{ zIndex: '-1' }}
                fileNeedTobeLive={this.state.fileNeedTobeLive}
                pipelineStage={this.state.pipelineStage}></Library>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Container>
        </Rerenderer>
      </React.Fragment>
    );
  }
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component location={location} navigate={navigate} {...props} />;
  }
  return ComponentWithRouterProp;
}

Home.propTypes = {
  props: PropTypes.bool,
  navigate: PropTypes.object
};

export default withRouter(Home);
