import React, { Component } from 'react';

class AppInfo extends Component {
  constructor(props) {
    super(props);

    // Get package.json for version info
    const pkgsJson = require('../../package.json');

    this.state = {
      appVersion: pkgsJson.version
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="appInfo">
        <br />
        CIPC File Uploader Version: {this.state.appVersion}
      </div>
    );
  }
}

export default AppInfo;
