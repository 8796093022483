import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  NavLink,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container
} from 'reactstrap';
import config from '../config';
import EnvIndicator from './EnvIndicator';
import AppInfo from './AppInfo';
import './Navbar.css';
import { Cache } from 'aws-amplify';
import PropTypes from 'prop-types';

// var Auth = null;
const umassMedLogo = {
  src: '/images/UMassChan_Vertical_2Color.png',
  style: { width: 65, height: 50 }
};

export default class MainNavbar extends React.Component {
  constructor(props) {
    super(props);

    // Auth = this.props.auth;

    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isOpen: false,
      modal: false,
      isAuthenticated: this.props.isAuthenticated
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  UNSAFE_componentWillUpdate(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isAuthenticated !== this.state.isAuthenticated) {
      this.setState({ isAuthenticated: nextProps.isAuthenticated });
    }
  }

  federatedLogin = async () => {
    try {
      await this.props.auth.federatedSignIn({
        provider: config.cognito.IDENTITY_PROVIDER
      });
    } catch (e) {
      /* empty */
    }
    this.props.auth.currentAuthenticatedUser().then(() => {});
  };

  redirect = (location) => {
    this.props.history.push({
      pathname: location,
      state: {
        isAuthenticated: this.state.isAuthenticated,
        courses: this.props.courses
      }
    });
  };

  render() {
    var children = [];
    if (typeof this.props.children === ['object Array']) {
      for (var i = 0; i < this.props.children.length; i++) {
        children.push(<NavItem key={'childNav-' + i}>{this.props.children[i]}</NavItem>);
      }
    } else if (typeof this.props.children === 'object') {
      children.push(<NavItem key={'childNav-' + i}>{this.props.children}</NavItem>);
    }

    return (
      <div
        style={{
          width: '100vw',
          position: 'fixed',
          boxShadow: '0 0 0.5em 0.5em rgba(0, 0, 0, 0.2)',
          zIndex: '100'
        }}
      >
        <Navbar color="light" title="navigation" light expand="md">
          <NavbarBrand>
            <span title="Website info">
              <img src={umassMedLogo.src} style={umassMedLogo.style} alt="UMASS Medical School" />
            </span>
          </NavbarBrand>
          <span onClick={this.toggleModal}> {this.props.brandname}</span>
          <NavbarToggler onClick={this.toggle} aria-label="Toggle navigation" />

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ms-auto" navbar>
              {this.state.isAuthenticated ? (
                <React.Fragment>
                  {children}
                  <NavItem
                    title="Current user"
                    aria-label={'Current logged in user is ' + Cache.getItem('Name')}
                  >
                    <NavLink href="#" color="primary">
                      {'User: ' + Cache.getItem('Name')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <span title="Log out of this website">
                      <Button
                        aria-label="Log out button"
                        onClick={() => this.props.logOut(() => this.redirect('/login'))}
                      >
                        Log Out
                      </Button>
                      {/* <NavLink className="NavLink" color="primary" onClick={() => this.props.logOut(() => this.redirect("/login"))} >Log Out</NavLink> */}
                    </span>
                  </NavItem>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Nav>
          </Collapse>
        </Navbar>
        <EnvIndicator position="top"></EnvIndicator>
        <Modal
          aria-label="Toggle navigation"
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>About CIPC File Uploader</ModalHeader>
          <ModalBody>
            <Container>
              <AppInfo childProps={this.props.childProps} />
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              {' '}
              Close{' '}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

MainNavbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.array,
  auth: PropTypes.object,
  history: PropTypes.object,
  courses: PropTypes.object,
  brandname: PropTypes.string,
  logOut: PropTypes.func,
  className: PropTypes.string,
  childProps: PropTypes.object
};
