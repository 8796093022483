const clientConfigurations = {
  'fileuploader.umassmedcloud.org': {
    s3: {
      ENDPOINT: 'arn:aws:s3:::cipc-files-bucket-test-production',
      TEST: 'cipc-files-bucket-test-production',
      LIVE: 'cipc-files-bucket-live-production',
      WEBAPP: 'cipc-webapp-deployment-bucket-production',
      TEST_DOMAIN: 'https://fileuploader-test.umassmedcloud.org',
      LIVE_DOMAIN: 'https://fileuploader-live.umassmedcloud.org',
      REGION: 'us-east-1'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_hUsXaEM8b',
      APP_CLIENT_ID: 'm1vek8e3b3k7snqffs6s7c9b4',
      IDENTITY_POOL_ID: 'us-east-1:16361bd5-25e8-46f6-b7b8-b6f53b588972',
      IDENTITY_PROVIDER: 'cipc-azure-sso',
      DOMAIN: 'cipc-fileuploader-production.auth.us-east-1.amazoncognito.com',
      REDIRECT: 'https://fileuploader.umassmedcloud.org/'
    },
    codeCommit: {
      BRANCH_NAME: 'master',
      REPOSITORY_NAME: 'cipc-files-repo-production',
      CLONE_URL:
        'https://git-codecommit.us-east-1.amazonaws.com/v1/repos/cipc-files-repo-production',
      ROLE_ARN: 'arn:aws:iam::413971791778:role/cipc-codecommit-role',
      REGION: 'us-east-1'
    },
    codePipeline: {
      REGION: 'us-east-1',
      PIPELINE: 'cipc-files-codepipeline-production',
      STAGE: 'Approval'
    }
  },
  'fileuploader.nonprod.umassmedcloud.org': {
    s3: {
      ENDPOINT: 'arn:aws:s3:::cipc-files-bucket-test-nonprod',
      TEST: 'cipc-files-bucket-test-nonprod',
      LIVE: 'cipc-files-bucket-live-nonprod',
      WEBAPP: 'cipc-webapp-deployment-bucket-nonprod',
      TEST_DOMAIN: 'https://fileuploader-test.nonprod.umassmedcloud.org',
      LIVE_DOMAIN: 'https://fileuploader-live.nonprod.umassmedcloud.org',
      REGION: 'us-east-1'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_DEtuKyyFo',
      APP_CLIENT_ID: '2erhb4rt2tnmjfo7cee8ihh573',
      IDENTITY_POOL_ID: 'us-east-1:ce549d1e-806a-4a76-97be-ba4d8227d575',
      IDENTITY_PROVIDER: 'cipc-azure-sso',
      DOMAIN: 'cipc-fileuploader-nonprod.auth.us-east-1.amazoncognito.com',
      REDIRECT: 'https://fileuploader.nonprod.umassmedcloud.org/'
    },
    codeCommit: {
      BRANCH_NAME: 'master',
      REPOSITORY_NAME: 'cipc-files-repo-nonprod',
      CLONE_URL: 'https://git-codecommit.us-east-1.amazonaws.com/v1/repos/cipc-files-repo-nonprod',
      ROLE_ARN: 'arn:aws:iam::251595763017:role/cipc-codecommit-role',
      REGION: 'us-east-1'
    },
    codePipeline: {
      REGION: 'us-east-1',
      PIPELINE: 'cipc-files-codepipeline-nonprod',
      STAGE: 'Approval'
    }
  },
  'fileuploader.engsand.umassmedcloud.org': {
    s3: {
      ENDPOINT: 'arn:aws:s3:::cipc-files-bucket-test-development',
      TEST: 'cipc-files-bucket-test-development',
      LIVE: 'cipc-files-bucket-live-development',
      WEBAPP: 'cipc-webapp-deployment-bucket-development',
      TEST_DOMAIN: 'https://fileuploader-test.engsand.umassmedcloud.org',
      LIVE_DOMAIN: 'https://fileuploader-live.engsand.umassmedcloud.org',
      REGION: 'us-east-1'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_UyZzXcc3D',
      APP_CLIENT_ID: '4eqvqamourk5690pdfropcng19',
      IDENTITY_POOL_ID: 'us-east-1:89991199-9dc2-4481-8c52-4a6e24572e17',
      IDENTITY_PROVIDER: 'cipc-azure-sso',
      DOMAIN: 'cipc-fileuploader-development.auth.us-east-1.amazoncognito.com',
      REDIRECT: 'https://fileuploader.engsand.umassmedcloud.org/'
    },
    codeCommit: {
      BRANCH_NAME: 'master',
      REPOSITORY_NAME: 'cipc-files-repo-development',
      CLONE_URL:
        'https://git-codecommit.us-east-1.amazonaws.com/v1/repos/cipc-files-repo-development',
      ROLE_ARN: 'arn:aws:iam::523893347658:role/cipc-codecommit-role',
      REGION: 'us-east-1'
    },
    codePipeline: {
      REGION: 'us-east-1',
      PIPELINE: 'cipc-files-codepipeline-development',
      STAGE: 'Approval'
    }
  },
  localhost: {
    s3: {
      ENDPOINT: 'arn:aws:s3:::cipc-files-bucket-test-development',
      TEST: 'cipc-files-bucket-test-development',
      LIVE: 'cipc-files-bucket-live-development',
      WEBAPP: 'cipc-webapp-deployment-bucket-development',
      TEST_DOMAIN: 'https://fileuploader-test.engsand.umassmedcloud.org',
      LIVE_DOMAIN: 'https://fileuploader-live.engsand.umassmedcloud.org',
      REGION: 'us-east-1'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_UyZzXcc3D',
      APP_CLIENT_ID: '4eqvqamourk5690pdfropcng19',
      IDENTITY_POOL_ID: 'us-east-1:89991199-9dc2-4481-8c52-4a6e24572e17',
      IDENTITY_PROVIDER: 'cipc-azure-sso',
      DOMAIN: 'cipc-fileuploader-development.auth.us-east-1.amazoncognito.com',
      REDIRECT: 'http://localhost:3000/'
    },
    codeCommit: {
      BRANCH_NAME: 'master',
      REPOSITORY_NAME: 'cipc-files-repo-development',
      CLONE_URL:
        'https://git-codecommit.us-east-1.amazonaws.com/v1/repos/cipc-files-repo-development',
      ROLE_ARN: 'arn:aws:iam::523893347658:role/cipc-codecommit-role',
      REGION: 'us-east-1'
    },
    codePipeline: {
      REGION: 'us-east-1',
      PIPELINE: 'cipc-files-codepipeline-development',
      STAGE: 'Approval'
    }
  }
};

function getConfig() {
  const { hostname } = window.location;
  return clientConfigurations[hostname];
}

module.exports = { getConfig };
