import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Rerenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seconds: this.props.seconds ? this.props.seconds : 1,
      time: new Date().getTime(),
      interval: null
    };
  }

  timer = () => {
    var interval = window.setInterval(this.rerender, this.state.seconds * 1000);
    this.setState({ interval: interval });
  };

  componentWillUnmount() {
    window.clearInterval(this.state.interval);
  }

  rerender = () => {
    this.setState({ time: new Date().getTime() });
  };

  componentDidMount() {
    this.timer();
  }

  render() {
    var children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        time: this.state.time
      });
    });
    return <React.Fragment>{children}</React.Fragment>;
  }
}

Rerenderer.propTypes = {
  seconds: PropTypes.number,
  children: PropTypes.object
};
