import React, { Component } from 'react';
import './PageTitle.css';
import PropTypes from 'prop-types';

export default class Title extends Component {
  render() {
    return (
      <div id="title" style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

Title.propTypes = {
  style: PropTypes.object,
  children: PropTypes.object
};
